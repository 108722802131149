import React, { useContext, useEffect } from "react";
import "./App.scss";

import {
  Routes,
  Route,
  Link,
  useNavigate,
  useLocation,
  Navigate,
} from "react-router-dom";

import { logusAuthProvider } from "./auth";
import { useCookies } from "react-cookie";
import {
  ManagementLayout,
  ManagementHome,
  ManagementCriancas,
} from "./management";
import { ErrorProvider, ErrorContext, Error } from "./commons/components/error";
import { AxiosError } from "axios";

function App() {
  return (
    <AuthProvider>
      <ErrorProvider>
        <Routes>
          <Route path="/" element={<PublicPage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route
            path="/management"
            element={
              <RequireAuth>
                <ManagementLayout />
              </RequireAuth>
            }
          >
            <Route index element={<ManagementHome />} />
            <Route path="criancas" element={<ManagementCriancas />} />
          </Route>
        </Routes>
      </ErrorProvider>
    </AuthProvider>
  );
}

interface AuthContextType {
  user: any;
  signin: (
    user: { email: string; password: string },
    callback: (data: any, error?: AxiosError) => any
  ) => void;
  signout: (callback: VoidFunction) => void;
}

let AuthContext = React.createContext<AuthContextType>(null!);

function AuthProvider({ children }: { children: React.ReactNode }) {
  let [user, setUser] = React.useState<any>(null);

  useEffect(() => {
    document.title = "Log.Us - Seeds";
  }, [user]);

  let signin = (
    data: { email: string; password: string },
    callback: (data: any, error?: AxiosError) => any
  ) => {
    return logusAuthProvider.signin(data, (token: any, error?: AxiosError) => {
      if (error) {
        callback(token, error);
        return;
      } else {
        setUser(token);
        callback(token);
      }
    });
  };

  let signout = (callback: VoidFunction) => {
    console.log("signout");
    setUser(null);
    callback();
  };

  let value = { user, signin, signout };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

function useAuth() {
  return React.useContext(AuthContext);
}

function RequireAuth({ children }: { children: JSX.Element }) {
  let auth = useAuth();
  let location = useLocation();
  const [cookies] = useCookies(["token"]);

  let token = cookies.token;

  if (!token && !auth.user) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  } else if (token && !auth.user) {
    auth.user = token;
  } else if (!token) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
}

function LoginPage() {
  let navigate = useNavigate();
  let location = useLocation();
  let auth = useAuth();

  let from = location.state?.from?.pathname || "/management";

  const [, setCookie] = useCookies(["token"]);
  let useError = useContext(ErrorContext);

  function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    if (!event.currentTarget.checkValidity()) {
      event.stopPropagation();
      useError.showError("Preencha os campos corretamente!");
      event.currentTarget.classList.add("was-validated");
    } else {
      let formData = new FormData(event.currentTarget);
      let username = formData.get("username") as string;
      let password = formData.get("password") as string;

      auth.signin(
        { email: username, password: password },
        (token: any, error?: AxiosError) => {
          if (error) {
            if (error.response) {
              let response: { description: { message: string } } = error
                .response.data
                ? (error.response.data as { description: { message: string } })
                : { description: { message: "" } };
              useError.showError(response.description.message);
            } else {
              useError.showError(error.message);
            }
          } else {
            setCookie("token", token);
            navigate(from, { replace: true });
          }
        }
      );
    }
  }

  return (
    <main className="d-flex justify-content-center align-items-center min-vw-100 min-vh-100 bg-primary">
      <Error />
      <form
        onSubmit={handleSubmit}
        className="d-flex flex-column justify-center items-center bg-white rounded p-5 login-form needs-validation"
        noValidate
      >
        <img
          src="/images/Seeds.jpeg"
          alt="Seeds Logo"
          className="img-fluid d-block mx-auto"
        ></img>
        <div className="form-floating p-1">
          <input
            type="email"
            className="form-control"
            name="username"
            id="staticEmail"
            placeholder="Email"
            required
          />
          <div className="invalid-feedback">Insira um email válido!</div>
          <label htmlFor="staticEmail">Email</label>
        </div>
        <div className="form-floating p-1">
          <input
            type="password"
            name="password"
            className="form-control"
            id="inputPassword"
            placeholder="Senha"
            required
          />
          <div className="invalid-feedback">Senha é necessária!</div>
          <label htmlFor="inputPassword">Senha</label>
        </div>
        <div className="d-grid gap-2 col-6 mx-auto p-2">
          <button type="submit" className="btn btn-primary btn-lg">
            Entrar
          </button>
        </div>
      </form>
    </main>
  );
}

function PublicPage() {
  return (
    <div className="container bg-orange">
      <div className="d-flex flex-column justify-content-center align-items-center min-vh-100">
        <div className="d-flex image-container justify-content-center align-items-center">
          <img
            className="img-fluid d-block mx-auto"
            src="/images/Seeds.jpeg"
            alt="Seeds Logo"
          />
        </div>
        <h2>
          Bem-vindo a plataforma de gestão <b>Log.Us - Módulo Seeds</b>
        </h2>
        <p>Efetue o login para seguir para a plataforma</p>
        <Link to="/management" className="btn btn-primary btn-lg">
          Entrar
        </Link>
      </div>
    </div>
  );
}

export default App;
export { AuthContext };
