import { Link } from "react-router-dom";
import { PeopleFill, HouseFill, PersonCircle } from "react-bootstrap-icons";
import { useLocation } from "react-router-dom";
import { AuthContext } from "../../../App";
import { useContext } from "react";
import { useCookies } from "react-cookie";
import React from "react";

function Sidebar(user: { username: string }) {
  let location = useLocation();
  let auth = useContext(AuthContext);
  const [, , removeCookie] = useCookies(["token"]);

  const handleLogout = () => {
    auth.signout(() => {
      removeCookie("token");
    });
  };
  return (
    <nav className="navbar nav-pills navbar-expand-lg bg-dark position-fixed top-0 w-100 z-1">
      <div className="container-fluid">
        <Link
          to="/management"
          className="d-flex align-items-center mb-3 mb-md-0 me-md-auto text-white text-decoration-none"
        >
          <h2 className="mb-0">Seeds</h2>
        </Link>
        <button
          className="navbar-toggler bg-white"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav me-auto mb-2 mb-lg-0 ms-0 ms-md-5">
            <li className="nav-item">
              <Link
                className={
                  "nav-link text-white" +
                  (location.pathname === "/management" ? " active" : "")
                }
                to="/management"
                aria-current="page"
              >
                <HouseFill className="me-2" />
                Início
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className={
                  "nav-link text-white" +
                  (location.pathname === "/management/criancas"
                    ? " active"
                    : "")
                }
                to="/management/criancas"
              >
                <PeopleFill className="me-2" />
                Crianças
              </Link>
            </li>
          </ul>
          <div className="dropdown">
            <button
              className="d-flex align-items-center text-white text-decoration-none dropdown-toggle bg-transparent border-0"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <PersonCircle className="me-2" />

              <strong>{user.username?.split(" ")[0]}</strong>
            </button>

            <ul className="dropdown-menu dropdown-menu-dark text-small shadow">
              <li>
                <hr className="dropdown-divider" />
              </li>
              <li>
                <button className="dropdown-item" onClick={handleLogout}>
                  Sair
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default Sidebar;
