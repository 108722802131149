import axios, { AxiosError } from "axios";
import config from "./commons/config/config";

interface ILoginBody {
  email: string;
  password: string;
}

interface IAuthToken {
  accessToken: string;
}

const fakeAuthProvider = {
  isAuthenticated: false,
  signin(callback: VoidFunction) {
    fakeAuthProvider.isAuthenticated = true;
    setTimeout(callback, 100); // fake async
  },
  signout(callback: VoidFunction) {
    fakeAuthProvider.isAuthenticated = false;
    setTimeout(callback, 100);
  },
};

const logusAuthProvider = {
  signin(data: ILoginBody, callback: (data: any, error?: AxiosError) => any) {
    axios
      .post(`${config().apiUrl}/auth/login`, data)
      .then((response) => {
        const token: IAuthToken = response.data;
        callback(token);
      })
      .catch((error) => {
        console.log(error);
        callback(null, error);
      });
  },
};

export { fakeAuthProvider, logusAuthProvider };
export type { ILoginBody, IAuthToken };
