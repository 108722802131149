import { ChangeEvent, useRef, useContext, useState, useEffect } from "react";
import Sidebar from "../commons/components/sidebar";
import { Outlet } from "react-router-dom";
import { AuthContext } from "../App";
import { useJwt } from "react-jwt";
import { useCookies } from "react-cookie";
import {
  PlusCircleDotted,
  DashCircleDotted,
  Search,
} from "react-bootstrap-icons";
import "./styles/index.scss";
import InputMask from "react-input-mask";
import React from "react";
import axios from "axios";
import { Toast } from "bootstrap";
import { ErrorContext, Error } from "../commons/components/error";
import config from "../commons/config/config";

interface IDecodedToken {
  name: string;
  email: string;
  iat: number;
  exp: number;
}

function ManagementLayout() {
  let auth = useContext(AuthContext);
  const { decodedToken, isExpired } = useJwt(auth.user.accessToken);
  let userObject: IDecodedToken = decodedToken as IDecodedToken;
  const [, , removeCookie] = useCookies(["token"]);
  // let navigate = useNavigate();
  // let location = useLocation();

  if (isExpired) {
    auth.signout(() => {
      // let from = location.state?.from?.pathname || "/";
      removeCookie("token");
    });
  }

  return (
    <div className="d-flex flex-row">
      <Sidebar username={userObject?.name}></Sidebar>
      <main className="container d-flex flex-column justify-content-center align-items-center py-5 mt-5">
        <Outlet />
      </main>
    </div>
  );
}

function ManagementHome() {
  return (
    <>
      <h1 className="text-orange-400 font-bold text-4xl">Bem-vindo ao</h1>
      <div className="container w-50">
        <img
          className="img-fluid d-block mx-auto"
          src="/images/Seeds.jpeg"
          alt="Seeds Logo"
        />
      </div>
    </>
  );
}

function ManagementCriancas() {
  let auth = useContext(AuthContext);
  let useError = useContext(ErrorContext);
  const successToastRef = useRef(null);
  const [criancas, setCriancas] = useState<any>([]);
  const [currentFilter, setCurrentFilter] = useState("");
  const [actualIndex, setActualIndex] = useState(0);
  const [responsavelLength, setResponsavelLength] = useState<number[]>([]);
  const [restricao, setRestricao] = useState(false);
  const [atencao, setAtencao] = useState(false);
  const [cepIsLoading, setcepIsLoading] = useState(false);
  const [cadastroIsLoading, setCadastroIsLoading] = useState(false);
  const [criancaIsLoading, setCriancaIsLoading] = useState(false);
  const [cepIsValid, setCepIsValid] = useState(true);
  const [enderecos, setEnderecos] = useState([
    {
      logradouro: "",
      complemento: "",
      bairro: "",
      localidade: "",
      uf: "",
    },
  ]);
  const [criancaEndereco, setCriancaEndereco] = useState({
    logradouro: "",
    complemento: "",
    bairro: "",
    localidade: "",
    uf: "",
  });

  useEffect(() => {
    setCriancaIsLoading(true);
    axios
      .get(`${config().apiUrl}/usuarios/respDependente`, {
        headers: {
          Authorization: `Bearer ${auth.user.accessToken}`,
        },
      })
      .then((response) => {
        setCriancaIsLoading(false);
        setCriancas(response.data._embedded.dependente);
      })
      .catch((error) => {
        setCriancaIsLoading(false);
        useError.showError(
          "Erro ao carregar crianças: " + error.response.data.message
        );
        console.log(error);
      });
  }, [auth.user.accessToken, useError]);

  const criancaFormRef = useRef<HTMLFormElement | undefined>();

  const handleRestricaoChange = (event: ChangeEvent<HTMLInputElement>) => {
    setRestricao(event.target.value === "true" ? true : false);
  };

  const handleAtencaoChange = (event: ChangeEvent<HTMLInputElement>) => {
    setAtencao(event.target.value === "true" ? true : false);
  };

  const handleAddResponsavel = () => {
    setResponsavelLength((prev) => {
      if (prev.length === 0) {
        return [0];
      } else {
        let last = prev[prev.length - 1];
        return [...prev, last + 1];
      }
    });
  };

  const handleRemoveResponsavel = (index: number) => {
    setResponsavelLength((prev) => {
      let newArray = prev.filter((item) => item !== index);
      return newArray;
    });
    setEnderecos((prev) => {
      let newArray = prev.filter((item, position) => position !== index);
      return newArray;
    });
  };

  const handleCEP = (event: ChangeEvent<HTMLInputElement>, index?: number) => {
    let formatedValue = event.target.value.replace(/[^\d]/g, "");
    if (formatedValue.length === 8) {
      setcepIsLoading(true);
      axios
        .get(`https://viacep.com.br/ws/${formatedValue}/json/`)
        .then((response) => {
          if (response.data.erro) {
            setCepIsValid(false);
            setcepIsLoading(false);
          } else {
            if (index !== undefined) {
              setEnderecos((prev) => {
                prev[index] = {
                  logradouro: response.data.logradouro,
                  complemento: response.data.complemento,
                  bairro: response.data.bairro,
                  localidade: response.data.localidade,
                  uf: response.data.uf,
                };
                return prev;
              });
            } else {
              setCriancaEndereco({
                logradouro: response.data.logradouro,
                complemento: response.data.complemento,
                bairro: response.data.bairro,
                localidade: response.data.localidade,
                uf: response.data.uf,
              });
            }

            setCepIsValid(true);
            setcepIsLoading(false);
          }
        });
    }
  };

  const handleCriancaSubmit = () => {
    const formData = criancaFormRef.current;
    const responsaveis = responsavelLength.map((value, index) => {
      return {
        nome: formData && formData["responsavel-" + value + "-nome"].value,
        telefoneCelular:
          formData && formData["responsavel-" + value + "-telefone"].value,
        email: formData && formData["responsavel-" + value + "-email"].value,
        grauParentesco:
          formData && formData["responsavel-" + value + "-parentesco"].value,
        endereco: {
          logradouro:
            formData &&
            formData["responsavel-" + value + "-endereco-logradouro"].value,
          numero:
            formData && formData["responsavel-" + value + "-numero"].value,
          complemento:
            formData &&
            formData["responsavel-" + value + "-endereco-complemento"].value,
          bairro:
            formData && formData["responsavel-" + value + "-bairro"].value,
          cidade:
            formData && formData["responsavel-" + value + "-localidade"].value,
          uf: formData && formData["responsavel-" + value + "-uf"].value,
          cep:
            formData &&
            formData["responsavel-" + value + "-endereco-cep"].value,
        },
        isMembro:
          formData &&
          JSON.parse(formData["responsavel-" + value + "-membro-radio"].value),
      };
    });
    const createBody = {
      nome: formData && formData["crianca-name"].value,
      genero: formData && formData["sexo-radio"].value,
      dataNascimento:
        formData &&
        new Date(formData["crianca-nascimento"].value).toISOString(),
      naturalidade: formData && formData["crianca-naturalidade"].value,
      atencaoEspecial: formData && JSON.parse(formData["atencao-radio"].value),
      atencaoEspecialDescricao:
        formData && formData["crianca-descricao-atencao"].value,
      restricaoAlimentar:
        formData && JSON.parse(formData["restricao-radio"].value),
      restricaoAlimentarDescricao:
        formData && formData["crianca-descricao-restricao"].value,
      observacaoGeral: formData && formData["crianca-observacoes-gerais"].value,
      autorizacaoAudioVisual:
        formData && JSON.parse(formData["uso-imagem-radio"].value),
      endereco: {
        logradouro: formData && formData["crianca-endereco-logradouro"].value,
        numero: formData && formData["crianca-endereco-numero"].value,
        complemento: formData && formData["crianca-endereco-complemento"].value,
        bairro: formData && formData["crianca-endereco-bairro"].value,
        cidade: formData && formData["crianca-endereco-localidade"].value,
        uf: formData && formData["crianca-endereco-uf"].value,
        cep: formData && formData["crianca-endereco-cep"].value,
      },
      responsaveis,
    };
    setCadastroIsLoading(true);
    axios
      .post(`${config().apiUrl}/usuarios/createDependente`, createBody, {
        headers: {
          Authorization: `Bearer ${auth.user.accessToken}`,
        },
      })
      .then((response) => {
        var myToast = successToastRef.current;
        var bsToast = Toast.getInstance(myToast || "");
        if (!bsToast) {
          console.log("Sem toast, instanciando");
          // initialize Toast
          bsToast = new Toast(myToast || "", { autohide: false });
          // hide after init
          bsToast.show();
        } else {
          console.log("Com toast instanciado");
          // toggle
          bsToast.show();
          console.log(bsToast);
        }
        setCadastroIsLoading(false);
      })
      .catch((error) => {
        useError.showError(
          "Erro ao cadastrar criança: " + error.response.data.message
        );
        setCadastroIsLoading(false);
        console.log(error);
      });
  };

  const handleFilter = (event: ChangeEvent<HTMLInputElement>) => {
    setCurrentFilter(event.target.value);
  };

  return (
    <div className="min-vh-100 w-100">
      <Error />
      <div
        className="toast align-items-center text-bg-success border-0 position-fixed top-0 end-0"
        style={{ zIndex: 10000 }}
        role="alert"
        aria-live="assertive"
        aria-atomic="true"
        ref={successToastRef}
      >
        <div className="d-flex">
          <div className="toast-body">Criança cadastrada com sucesso!</div>
          <button
            type="button"
            className="btn-close btn-close-white me-2 m-auto"
            data-bs-dismiss="toast"
            aria-label="Fechar"
          ></button>
        </div>
      </div>

      <h1 className="mb-5">Crianças</h1>

      <div
        className={
          "w-100 d-flex justify-content-center " +
          (criancaIsLoading ? "" : "d-none")
        }
      >
        <div
          className="spinner-border text-primary"
          style={{ width: "4em", height: "4em" }}
        ></div>
      </div>

      {criancas.length === 0 && criancaIsLoading === false && (
        <div className="container flex w-100">
          <p>Nenhum registro encontrado.</p>
        </div>
      )}

      {criancas.length > 0 && (
        <div className="container text-center w-100">
          <div className="input-group flex-nowrap mb-5">
            <span className="input-group-text">
              <Search />
            </span>
            <input
              type="email"
              className="form-control"
              id="floatingInputValue"
              placeholder="Buscar"
              onChange={handleFilter}
            />
          </div>

          <div className="row row-cols-3">
            {criancas.filter((item: any) => item.nome.includes(currentFilter))
              .length > 0 ? (
              criancas
                .filter((item: any) => item.nome.includes(currentFilter))
                .map((crianca: any, index: number) => {
                  // let birth = new Date(crianca.dataNascimento);
                  // let today = new Date().getFullYear();
                  return (
                    <div key={index} className="col-12 col-sm-4">
                      <div className="card">
                        <h5 className="card-header">{crianca.nome}</h5>
                        <div className="card-body text-start">
                          <h5 className="card-title">
                            <b>Gênero:</b> {crianca.genero}
                          </h5>
                          <h5 className="card-text">
                            <b>Atenção Especial?: </b>
                            {crianca.atencaoEspecial ? "Sim" : "Não"}
                          </h5>
                          <h5 className="card-text">
                            <b>Restrição Alimentar?: </b>
                            {crianca.atencaoEspecial ? "Sim" : "Não"}
                          </h5>
                          <button
                            className="btn btn-primary mt-3"
                            data-bs-toggle="modal"
                            data-bs-target="#viewModal"
                            onClick={() => setActualIndex(index)}
                          >
                            Ver informações
                          </button>
                        </div>
                      </div>
                    </div>
                  );
                })
            ) : (
              <div className="col-12">
                <h2>Nenhum resultado encontrado</h2>
              </div>
            )}
          </div>
        </div>
      )}

      <div
        className="modal modal-xl fade"
        id="viewModal"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="exampleModalLabel">
                Informações
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Fechar"
              ></button>
            </div>
            <div className="modal-body">
              <div className="row row-cols-3">
                <div className="col-12 col-sm-4">
                  <b>Nome:</b>
                  <p>{criancas[actualIndex]?.nome}</p>
                </div>
                <div className="col-12 col-sm-4">
                  <b>Data de Nascimento:</b>
                  <p>
                    {new Date(
                      criancas[actualIndex]?.dataNascimento
                    ).toLocaleDateString()}
                  </p>
                </div>
                <div className="col-12 col-sm-4">
                  <b>Gênero:</b>
                  <p>{criancas[actualIndex]?.genero}</p>
                </div>
              </div>

              <div className="row row-cols-3">
                <div className="col-12 col-sm-4">
                  <b>Natural de:</b>
                  <p>{criancas[actualIndex]?.naturalidade}</p>
                </div>
                <div className="col-12 col-sm-4">
                  <b>Atenção especial?:</b>
                  <p>
                    {criancas[actualIndex]?.atencaoEspecial ? "Sim" : "Não"},{" "}
                    {criancas[actualIndex]?.atencaoEspecialDescricao}
                  </p>
                </div>
                <div className="col-12 col-sm-4">
                  <b>Restrição Alimentar?:</b>
                  <p>
                    {criancas[actualIndex]?.restricaoAlimentar ? "Sim" : "Não"},{" "}
                    {criancas[actualIndex]?.restricaoAlimentarDescricao}
                  </p>
                </div>
              </div>

              <hr />
              <h5>Endereço</h5>
              <div className="row row-cols-4">
                <div className="col-12 col-sm-4">
                  <b>Logradouro:</b>
                  <p>{criancas[actualIndex]?.endLogradouro}</p>
                </div>
                <div className="col-12 col-sm-2">
                  <b>Numero:</b>
                  <p>{criancas[actualIndex]?.endNumero}</p>
                </div>
                <div className="col-12 col-sm-4">
                  <b>Complemento:</b>
                  <p>{criancas[actualIndex]?.endComplemento}</p>
                </div>
                <div className="col-12 col-sm-2">
                  <b>CEP:</b>
                  <p>{criancas[actualIndex]?.endCep}</p>
                </div>
              </div>
              <div className="row row-cols-3">
                <div className="col-12 col-sm-4">
                  <b>Bairro:</b>
                  <p>{criancas[actualIndex]?.endBairro}</p>
                </div>
                <div className="col-12 col-sm-4">
                  <b>Cidade:</b>
                  <p>{criancas[actualIndex]?.endCidade}</p>
                </div>
                <div className="col-12 col-sm-4">
                  <b>UF:</b>
                  <p>{criancas[actualIndex]?.endUf}</p>
                </div>
              </div>

              <hr />
              <h5>Responsáveis</h5>
              {criancas[actualIndex]?.responsavel.map(
                (responsavel: any, index: number) => {
                  return (
                    <div className="p-4">
                      <div className="row row-cols-3">
                        <div className="col-12 col-sm-4">
                          <b>Nome:</b>
                          <p>{responsavel.responsavel?.nome}</p>
                        </div>
                        <div className="col-12 col-sm-4">
                          <b>Email:</b>
                          <p>{responsavel.responsavel?.email}</p>
                        </div>
                        <div className="col-12 col-sm-4">
                          <b>Grau de Parentesco:</b>
                          <p>{responsavel.responsavel?.grauParentesco}</p>
                        </div>
                      </div>
                      <div className="row row-cols-3">
                        <div className="col-12 col-sm-4">
                          <b>Data de Nascimento:</b>
                          <p>{responsavel.responsavel?.dataNascimento}</p>
                        </div>
                        <div className="col-12 col-sm-4">
                          <b>Telefone:</b>
                          <p>{responsavel.responsavel?.telefoneCelular}</p>
                        </div>
                        <div className="col-12 col-sm-4">
                          <b>Gênero:</b>
                          <p>{responsavel.responsavel?.genero}</p>
                        </div>
                      </div>
                      <div className="row row-cols-3">
                        <div className="col-12 col-sm-4">
                          <b>CPF:</b>
                          <p>{responsavel.responsavel?.cpf}</p>
                        </div>
                        <div className="col-12 col-sm-4">
                          <b>Membro?:</b>
                          <p>
                            {responsavel.responsavel?.isMembro ? "Sim" : "Não"}
                          </p>
                        </div>
                        <b>Endereço</b>
                      </div>
                      <div className="row row-cols-4">
                        <div className="col-12 col-sm-4">
                          <b>Logradouro:</b>
                          <p>{responsavel.responsavel?.endLogradouro}</p>
                        </div>
                        <div className="col-12 col-sm-2">
                          <b>Numero:</b>
                          <p>{responsavel.responsavel?.endNumero}</p>
                        </div>
                        <div className="col-12 col-sm-4">
                          <b>Complemento:</b>
                          <p>{responsavel.responsavel?.endComplemento}</p>
                        </div>
                        <div className="col-12 col-sm-2">
                          <b>CEP:</b>
                          <p>{responsavel.responsavel?.endCep}</p>
                        </div>
                      </div>
                      <div className="row row-cols-3">
                        <div className="col-12 col-sm-4">
                          <b>Bairro:</b>
                          <p>{responsavel.responsavel?.endBairro}</p>
                        </div>
                        <div className="col-12 col-sm-4">
                          <b>Cidade:</b>
                          <p>{responsavel.responsavel?.endCidade}</p>
                        </div>
                        <div className="col-12 col-sm-4">
                          <b>UF:</b>
                          <p>{responsavel.responsavel?.endUf}</p>
                        </div>
                      </div>
                      <hr />
                    </div>
                  );
                }
              )}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Fechar
              </button>
              <button type="button" disabled className="btn btn-primary">
                Salvar alterações
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="responsavelModal"
        tabIndex={-1}
        aria-labelledby="responsavelModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="responsavelModalLabel">
                Cadastro de criança
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Fechar"
              ></button>
            </div>
            <div className="modal-body">
              <form
                ref={criancaFormRef as any}
                noValidate
                className="needs-validation"
              >
                <div className="mb-3 row">
                  <div className="col">
                    <label htmlFor="crianca-name" className="col-form-label">
                      Nome:
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="crianca-name"
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col">
                    <label
                      htmlFor="crianca-nascimento"
                      className="col-form-label"
                    >
                      Data de Nascimento:
                    </label>
                    <input
                      type="date"
                      className="form-control"
                      id="crianca-nascimento"
                    ></input>
                  </div>

                  <div className="col">
                    <label
                      htmlFor="crianca-naturalidade"
                      className="col-form-label"
                    >
                      Naturalidade:
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="crianca-naturalidade"
                    ></input>
                  </div>
                </div>

                <div className="row">
                  <div className="col">
                    <label className="col-form-label">Sexo</label>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="sexo-radio"
                        value={"masculino"}
                        id="sexo-radio-masculino"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="sexo-radio-masculino"
                      >
                        Masculino
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="sexo-radio"
                        value={"feminino"}
                        id="sexo-radio-feminino"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="sexo-radio-feminino"
                      >
                        Feminino
                      </label>
                    </div>
                  </div>
                </div>

                <hr />
                <b>Endereço</b>
                <div className="row">
                  <div className="col">
                    <label
                      htmlFor={"crianca-endereco-cep"}
                      className="col-form-label"
                    >
                      CEP:
                    </label>
                    <div className="input-group mb-3">
                      <span
                        className={
                          "input-group-text " + (cepIsLoading ? "" : "d-none")
                        }
                        role="status"
                      >
                        <div className="spinner-border spinner-border-sm"></div>
                      </span>
                      <InputMask
                        disabled={cepIsLoading}
                        type="text"
                        className={
                          "form-control " + (cepIsValid ? "" : "is-invalid")
                        }
                        id={"crianca-endereco-cep"}
                        mask={"99999-999"}
                        onChange={(e) => handleCEP(e)}
                      ></InputMask>
                    </div>
                    <div
                      className={
                        "invalid-feedback " + (cepIsValid ? "" : "d-block")
                      }
                    >
                      CEP Inválido, por favor insira um CEP válido.
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <label
                      htmlFor="crianca-endereco-logradouro"
                      className="col-form-label"
                    >
                      Logradouro:
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="crianca-endereco-logradouro"
                      defaultValue={criancaEndereco?.logradouro}
                    ></input>
                  </div>

                  <div className="col-3">
                    <label
                      htmlFor="crianca-endereco-numero"
                      className="col-form-label"
                    >
                      Número:
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      id="crianca-endereco-numero"
                    ></input>
                  </div>
                </div>

                <div className="row">
                  <div className="col">
                    <label
                      htmlFor={"crianca-endereco-bairro"}
                      className="col-form-label"
                    >
                      Bairro:
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id={"crianca-endereco-bairro"}
                      defaultValue={criancaEndereco?.bairro}
                    ></input>
                  </div>
                </div>

                <div className="row">
                  <div className="col">
                    <label
                      htmlFor={"crianca-endereco-localidade"}
                      className="col-form-label"
                    >
                      Cidade:
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id={"crianca-endereco-localidade"}
                      defaultValue={criancaEndereco?.localidade}
                    ></input>
                  </div>

                  <div className="col-3">
                    <label
                      htmlFor={"crianca-endereco-uf"}
                      className="col-form-label"
                    >
                      UF:
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id={"crianca-endereco-uf"}
                      defaultValue={criancaEndereco?.uf}
                    ></input>
                  </div>
                </div>

                <div className="row">
                  <div className="col">
                    <label
                      htmlFor="crianca-endereco-complemento"
                      className="col-form-label"
                    >
                      Complemento:
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="crianca-endereco-complemento"
                      defaultValue={criancaEndereco?.complemento}
                    ></input>
                  </div>
                </div>

                <hr />

                <div className="row">
                  <div className="col">
                    <label className="col-form-label">
                      Possuí restrição alimentar?
                    </label>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="restricao-radio"
                        id="restricao-radio-sim"
                        value={"true"}
                        onChange={handleRestricaoChange}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="restricao-radio-sim"
                      >
                        Sim
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="restricao-radio"
                        id="restricao-radio-nao"
                        value={"false"}
                        onChange={handleRestricaoChange}
                        checked={restricao === false}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="restricao-radio-nao"
                      >
                        Não
                      </label>
                    </div>
                    <div
                      className={"row " + (restricao === true ? "" : "d-none")}
                    >
                      <div className="col">
                        <label
                          htmlFor="crianca-descricao-restricao"
                          className="col-form-label"
                        >
                          Qual?
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="crianca-descricao-restricao"
                        ></input>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col">
                    <label className="col-form-label">
                      Possuí algum motivo de atenção?
                    </label>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="atencao-radio"
                        id="atencao-radio-sim"
                        value={"true"}
                        onChange={handleAtencaoChange}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="atencao-radio-sim"
                      >
                        Sim
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="atencao-radio"
                        id="atencao-radio-nao"
                        value={"false"}
                        onChange={handleAtencaoChange}
                        checked={atencao === false}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="atencao-radio-nao"
                      >
                        Não
                      </label>
                    </div>
                    <div
                      className={"row " + (atencao === true ? "" : "d-none")}
                    >
                      <div className="col">
                        <label
                          htmlFor="crianca-descricao-atencao"
                          className="col-form-label"
                        >
                          Qual?
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="crianca-descricao-atencao"
                        ></input>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col">
                    <label className="col-form-label">
                      Autoriza o uso de imagem e voz em nossas redes sociais?
                    </label>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="uso-imagem-radio"
                        id="uso-imagem-radio-sim"
                        value={"true"}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="uso-imagem-radio-sim"
                      >
                        Sim, eu autorizo
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="uso-imagem-radio"
                        id="uso-imagem-radio-nao"
                        value={"false"}
                        defaultChecked
                      />
                      <label
                        className="form-check-label"
                        htmlFor="uso-imagem-radio-nao"
                      >
                        Não, eu não autorizo
                      </label>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col">
                    <label
                      className="col-form-label"
                      htmlFor="crianca-observacoes-gerais"
                    >
                      Observações gerais
                    </label>
                    <textarea
                      className="form-control"
                      id="crianca-observacoes-gerais"
                    ></textarea>
                  </div>
                </div>

                <hr />

                <div className="row">
                  <b>Responsáveis</b>
                </div>
                {responsavelLength.map((value, index) => {
                  return (
                    <div key={index} className="row border border-2 p-3">
                      <b>Responsável {value}</b>
                      <div className="row">
                        <div className="col">
                          <label
                            htmlFor={"responsavel-" + value + "-nome"}
                            className="col-form-label"
                          >
                            Nome:
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id={"responsavel-" + value + "-nome"}
                          />
                        </div>
                      </div>

                      <div className="row">
                        <div className="col">
                          <label
                            htmlFor={"responsavel-" + value + "-cpf"}
                            className="col-form-label"
                          >
                            CPF:
                          </label>
                          <InputMask
                            type="text"
                            className="form-control"
                            id={"responsavel-" + value + "-cpf"}
                            mask={"999.999.999-99"}
                          ></InputMask>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col">
                          <label
                            htmlFor={"responsavel-" + value + "-telefone"}
                            className="col-form-label"
                          >
                            Telefone:
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id={"responsavel-" + value + "-telefone"}
                          />
                        </div>
                        <div className="col">
                          <label
                            htmlFor={"responsavel-" + value + "-email"}
                            className="col-form-label"
                          >
                            Email:
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id={"responsavel-" + value + "-email"}
                          />
                        </div>
                      </div>

                      <div className="row">
                        <div className="col">
                          <label
                            htmlFor={"responsavel-" + value + "-parentesco"}
                            className="col-form-label"
                          >
                            Grau de parentesco:
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id={"responsavel-" + value + "-parentesco"}
                          />
                        </div>
                      </div>

                      <b className="mt-3">Endereço</b>
                      <div className="row">
                        <div className="col">
                          <label
                            htmlFor={"responsavel-" + value + "-endereco-cep"}
                            className="col-form-label"
                          >
                            CEP:
                          </label>
                          <div className="input-group mb-3">
                            <span
                              className={
                                "input-group-text " +
                                (cepIsLoading ? "" : "d-none")
                              }
                              role="status"
                            >
                              <div className="spinner-border spinner-border-sm"></div>
                            </span>
                            <InputMask
                              disabled={cepIsLoading}
                              type="text"
                              className={
                                "form-control " +
                                (cepIsValid ? "" : "is-invalid")
                              }
                              id={"responsavel-" + value + "-endereco-cep"}
                              mask={"99999-999"}
                              onChange={(e) => handleCEP(e, value)}
                            ></InputMask>
                          </div>
                          <div
                            className={
                              "invalid-feedback " +
                              (cepIsValid ? "" : "d-block")
                            }
                          >
                            CEP Inválido, por favor insira um CEP válido.
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col">
                          <label
                            htmlFor={
                              "responsavel-" + value + "-endereco-logradouro"
                            }
                            className="col-form-label"
                          >
                            Logradouro:
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id={"responsavel-" + value + "-endereco-logradouro"}
                            defaultValue={enderecos[value]?.logradouro}
                          ></input>
                        </div>

                        <div className="col-3">
                          <label
                            htmlFor={"responsavel-" + value + "-numero"}
                            className="col-form-label"
                          >
                            Número:
                          </label>
                          <input
                            type="number"
                            className="form-control"
                            id={"responsavel-" + value + "-numero"}
                          ></input>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col">
                          <label
                            htmlFor={"responsavel-" + value + "-bairro"}
                            className="col-form-label"
                          >
                            Bairro:
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id={"responsavel-" + value + "-bairro"}
                            defaultValue={enderecos[value]?.bairro}
                          ></input>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col">
                          <label
                            htmlFor={"responsavel-" + value + "-localidade"}
                            className="col-form-label"
                          >
                            Cidade:
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id={"responsavel-" + value + "-localidade"}
                            defaultValue={enderecos[value]?.localidade}
                          ></input>
                        </div>

                        <div className="col-3">
                          <label
                            htmlFor={"responsavel-" + value + "-uf"}
                            className="col-form-label"
                          >
                            UF:
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id={"responsavel-" + value + "-uf"}
                            defaultValue={enderecos[value]?.uf}
                          ></input>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col">
                          <label
                            htmlFor={
                              "responsavel-" + value + "-endereco-complemento"
                            }
                            className="col-form-label"
                          >
                            Complemento:
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id={
                              "responsavel-" + value + "-endereco-complemento"
                            }
                            defaultValue={enderecos[value]?.complemento}
                          ></input>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col">
                          <label className="col-form-label">
                            É membro Cristo Vive SP?
                          </label>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="radio"
                              name={"responsavel-" + value + "-membro-radio"}
                              id={"responsavel-" + value + "-membro-sim"}
                              value={"true"}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={"responsavel-" + value + "-membro-sim"}
                            >
                              Sim
                            </label>
                          </div>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="radio"
                              name={"responsavel-" + value + "-membro-radio"}
                              id={"responsavel-" + value + "-membro-nao"}
                              value={"false"}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={"responsavel-" + value + "-membro-nao"}
                            >
                              Não
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="row mt-3">
                        <button
                          type="button"
                          className="btn btn-danger col-7 ms-auto"
                          onClick={() => handleRemoveResponsavel(value)}
                        >
                          <DashCircleDotted className="me-2" />
                          Remover responsável
                        </button>
                      </div>
                    </div>
                  );
                })}

                <div className="row mt-3">
                  <button
                    type="button"
                    className="btn btn-primary col-7 ms-4"
                    onClick={handleAddResponsavel}
                  >
                    <PlusCircleDotted className="me-2" />
                    Adicionar responsável
                  </button>
                </div>
              </form>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Fechar
              </button>
              <button
                type="button"
                disabled={cadastroIsLoading}
                onClick={handleCriancaSubmit}
                className="btn btn-primary"
              >
                Cadastrar
                <div
                  className={
                    "spinner-border spinner-border-sm ms-1 " +
                    (cadastroIsLoading ? "" : "d-none")
                  }
                ></div>
              </button>
            </div>
          </div>
        </div>
      </div>
      <button
        className="position-fixed end-0 bottom-0 px-4 py-3 rounded-pill text-bg-primary border-0 translate-middle float-button"
        data-bs-toggle="modal"
        data-bs-target="#responsavelModal"
      >
        <PlusCircleDotted className="me-2" />
        Nova Criança
      </button>
    </div>
  );
}

export { ManagementLayout, ManagementHome, ManagementCriancas };
