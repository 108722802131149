import { useState, useRef, useEffect, createContext, useContext } from "react";
import { Toast } from "bootstrap";
import React from "react";

function Error() {
  let error = useContext(ErrorContext);
  const errorToastRef = useRef(null);

  useEffect(() => {
    console.log(error.show);
    var myToast = errorToastRef.current;
    var bsToast = Toast.getInstance(myToast || "");
    if (!bsToast) {
      // initialize Toast
      bsToast = new Toast(myToast || "", { autohide: false });
      // hide after init
      bsToast.hide();
    } else {
      // toggle
      error.show ? bsToast.show() : bsToast.hide();
    }
  }, [error]);

  return (
    <div
      id="errorToast"
      className="toast align-items-center text-bg-danger border-0 position-fixed bottom-0 end-0 p-1"
      style={{ zIndex: 10000 }}
      role="alert"
      aria-live="assertive"
      ref={errorToastRef}
    >
      <div className="d-flex">
        <div className="toast-body">{error.message}</div>
        <button
          type="button"
          className="btn-close btn-close-white me-2 m-auto"
          data-bs-dismiss="toast"
          aria-label="Close"
          onClick={() => {
            error.hideError();
          }}
        ></button>
      </div>
    </div>
  );
}

interface ErrorContextType {
  show: boolean;
  message: string;
  showError: (message: string) => void;
  hideError: () => void;
}

let ErrorContext = createContext<ErrorContextType>(null!);

function ErrorProvider({ children }: { children: React.ReactNode }) {
  let [show, setShow] = useState<any>(null);
  let [message, setMessage] = useState<any>(null);

  let showError = (message: string) => {
    setMessage(message);
    setShow(true);
  };

  let hideError = () => {
    setShow(false);
  };

  let value = { show, message, showError, hideError };

  return (
    <ErrorContext.Provider value={value}>{children}</ErrorContext.Provider>
  );
}

export { ErrorContext, ErrorProvider, Error };
